<template>
  <div v-if="loading === false">
    <div class="tw-flex-no-wrap tw-mb-6 tw-flex tw-overflow-y-auto tw-pb-3 tw-pt-2">
      <FilterCriteriaPill :filter-options-processed="filterOptionsProcessed" :search-options-processed="searchOptionsProcessed" label="Art" criteria-name="typeNames" @filter-changed="filterChange" />
      <FilterCriteriaPill :filter-options-processed="filterOptionsProcessed" :search-options-processed="searchOptionsProcessed" label="Verein" criteria-name="pageTitles" @filter-changed="filterChange" />
      <FilterCriteriaPill :filter-options-processed="filterOptionsProcessed" :search-options-processed="searchOptionsProcessed" label="Kategorie" criteria-name="categoryTitles" @filter-changed="filterChange" />
      <FilterSearchPhrasePill :search-options-processed="searchOptionsProcessed" @search-phrase-changed="searchPhraseChange" />
      <div
        type="button"
        @click="openFilterDialog"
        class="tw-relative tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-3xl tw-bg-slate-900 tw-px-6 tw-py-3 tw-text-sm tw-font-bold tw-text-white tw-shadow-sm hover:tw-bg-slate-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-ring-offset-2"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tw-mr-3 tw-h-6 tw-w-6">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
          />
        </svg>

        Suche & Filter
        <div
          v-if="filterCount > 0"
          class="tw-absolute tw--right-2 tw--top-2 tw-inline-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-solid tw-border-slate-900 tw-bg-white tw-text-sm tw-font-bold tw-text-slate-900"
        >
          {{ filterCount }}
        </div>
      </div>
    </div>
    <div class="tw-mb-8 tw-rounded-3xl tw-bg-gray-100 tw-p-6 sm:tw-p-8" v-if="helpArticleHits.length > 0">
      <div class="tw-font-semibold">Hilfeartikel</div>
      <div v-for="hit in helpArticleHits" :key="hit._id">
        <div class="tw-flex tw-flex-wrap tw-items-center tw-py-2 sm:tw-py-1">
          <router-link
            class="tw-mr-1.5 tw-text-gray-900 tw-no-underline hover:tw-text-gray-700 hover:tw-underline"
            :to="{ name: searchOptionsProcessed.helpArticleTargetPage, params: { contentArticleHelpSlug: hit._source.contentArticleHelp.slug } }"
            >{{ hit._source.contentArticleHelp.title }}</router-link
          >
          <div
            v-if="hit._source.contentArticleHelp.targetGroups && hit._source.contentArticleHelp.targetGroups.includes('user')"
            class="tw-mb--0.5 tw-mr-1.5 tw-mt-0.5 tw-block tw-items-center tw-justify-items-start tw-truncate tw-rounded tw-bg-teal-100 tw-px-2 tw-py-0.5 tw-text-[11px] tw-font-light tw-text-teal-800"
          >
            für Nutzer
          </div>
          <div
            v-if="hit._source.contentArticleHelp.targetGroups && hit._source.contentArticleHelp.targetGroups.includes('trainer')"
            class="tw-mb--0.5 tw-mr-1.5 tw-mt-0.5 tw-block tw-items-center tw-justify-items-start tw-truncate tw-rounded tw-bg-sky-100 tw-px-2 tw-py-0.5 tw-text-[11px] tw-font-light tw-text-sky-800"
          >
            für Trainer
          </div>
          <div
            v-if="hit._source.contentArticleHelp.targetGroups && hit._source.contentArticleHelp.targetGroups.includes('page')"
            class="tw-mb--0.5 tw-mt-0.5 tw-block tw-items-center tw-justify-items-start tw-truncate tw-rounded tw-bg-blue-100 tw-px-2 tw-py-0.5 tw-text-[11px] tw-font-light tw-text-blue-800"
          >
            für Vereine
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-wrap" v-if="loading === false">
      <div v-for="hit in mainHits" :key="hit.id" :data-hit-_source-type="hit._source.type" :data-hit-_id="hit._id" style="width: 340px; margin-right:16px; margin-bottom:16px">
        <div
          v-if="hit._source.type === 'Page'"
          v-bind:class="{
            'tw-h-128': $store.state.site.backend.token !== 'AJQWEO2TML',
          }"
        >
          <PageCard :page="hit._source.page" :search-options="searchOptionsProcessed" />
        </div>
        <div
          v-else-if="hit._source.type === 'CourseDateSet'"
          v-bind:class="{
            'tw-h-128': $store.state.site.backend.token !== 'AJQWEO2TML',
          }"
        >
          <CourseDateSetCard :course-date-set="hit._source.courseDateSet" :search-options="searchOptionsProcessed" />
        </div>
        <div
          v-else-if="hit._source.type === 'Subscription'"
          v-bind:class="{
            'tw-h-128': $store.state.site.backend.token !== 'AJQWEO2TML',
          }"
        >
          <SubscriptionCard :subscription="hit._source.subscription" :search-options="searchOptionsProcessed" />
        </div>
        <div
          v-else-if="hit._source.type === 'ContentArticleBlog'"
          v-bind:class="{
            'tw-h-128': $store.state.site.backend.token !== 'AJQWEO2TML',
          }"
        >
          <ContentArticleBlogCard :content-article-blog="hit._source.contentArticleBlog" :search-options="searchOptionsProcessed" />
        </div>
      </div>
    </div>
    <!--<div>
      pagesize: {{pageSize}}<br>offset: {{offset}}<br>countHits: {{countHits}}<br>countHitsLeftToLoad: {{countHitsLeftToLoad}}<br>allResultsLoaded: {{allResultsLoaded}}
    </div>-->
    <div class="tw-mt-4 tw-flex tw-justify-center" v-if="countHitsLeftToLoad >= 0">
      <helios-button @click="loadMore" color="primary">Weitere Ergebnisse laden (noch {{ countHitsLeftToLoad }} von {{ countHits }})</helios-button>
    </div>
    <FilterDialog
      ref="filterDialog"
      :search-result="searchResult"
      :filter-options-processed="filterOptionsProcessed"
      :search-options-processed="searchOptionsProcessed"
      @filter-changed="filterChange"
      @search-phrase-changed="searchPhraseChange"
    />
  </div>
</template>
<script>
  import PageCard from './_page.vue';
  import CourseDateSetCard from './_courseDateSet.vue';
  import SubscriptionCard from './_subscription.vue';
  import ContentArticleBlogCard from './_contentArticleBlog.vue';
  import FilterDialog from './_filterDialog.vue';
  import FilterCriteriaPill from './_filterCriteriaPill.vue';
  import FilterSearchPhrasePill from './_filterSearchPhrasePill.vue';
  import { GetFromNestedObjectMixin } from 'helios/mixins/getFromNestedObject';

  const elasticSearch = require('./search.js');

  export default {
    mixins: [GetFromNestedObjectMixin],
    props: {
      searchOptions: {
        type: Object,
      },
    },
    components: {
      PageCard,
      CourseDateSetCard,
      SubscriptionCard,
      ContentArticleBlogCard,
      FilterDialog,
      FilterCriteriaPill,
      FilterSearchPhrasePill,
    },
    data() {
      return {
        searchResult: undefined,
        searchResultHits: [],
        searchResultContentArticleHelp: undefined,
        searchOptionsProcessed: undefined,
        filterOptionsProcessed: undefined,
        filterCount: 0,
        loading: true,
        valueUp: 0,
        currentRequestId: 0,
        latestSearchId: null,
        offset: 0,
        pageSize: 48,
        allResultsLoaded: false,
      };
    },
    watch: {
      searchOptions: {
        deep: true,
        handler() {
          this.manageSearchObject();
        },
      },
      searchOptionsProcessed: {
        deep: true,
        handler() {
          this.search();
        },
      },
    },
    computed: {
      displayHelpArticles() {
        if (this.searchOptionsProcessed.displayHelpArticlesOnLoad === true) {
          return true;
        } else if (this.searchOptionsProcessed.searchPhrase.length >= 3) {
          return true;
        } else {
          return false;
        }
      },
      mainHits() {
        if (this.searchResult && this.searchResult.hits && this.searchResult.hits.hits) {
          return this.searchResult.hits.hits.filter((hit) => ['Page', 'CourseDateSet', 'Subscription', 'ContentArticleBlog'].includes(hit._source.type));
        }
        return [];
      },
      helpArticleHits() {
        if (this.displayHelpArticles && this.searchResult && this.searchResult.hits && this.searchResult.hits.hits) {
          return this.searchResult.hits.hits.filter((hit) => ['ContentArticleHelp'].includes(hit._source.type));
        }
        return [];
      },
      countHits() {
        if (this.searchResult && this.searchResult.hits && this.searchResult.hits.total) {
          return this.searchResult.hits.total.value;
        }
        return 0;
      },
      countHitsLeftToLoad() {
        if (this.searchResult && this.searchResult.hits && this.searchResult.hits.total) {
          return this.searchResult.hits.total.value - this.offset - this.pageSize;
        }
        return 0;
      },
    },
    methods: {
      loadMore() {
        // If all results are loaded, do nothing
        if (this.allResultsLoaded) {
          return;
        }

        if (this.offset + this.pageSize < this.countHits) {
          this.offset += this.pageSize;
          console.log('---- loadMore if');
        } else {
          this.offset = Math.max(this.countHits - this.pageSize, 0);
          this.allResultsLoaded = true; // Set the flag to true when all results are loaded
          console.log('---- loadMore else');
        }

        this.search({ loadMore: true });
        console.log('---- loadMore', this.offset);
      },
      filterChange(newFilter) {
        this.searchOptionsProcessed.criteria.typeNames = [];
        newFilter.typeNames
          .filter((item) => item.selected === true)
          .forEach((item) => {
            this.searchOptionsProcessed.criteria.typeNames.push(item.value);
          });

        this.searchOptionsProcessed.criteria.pageTitles = [];
        newFilter.pageTitles
          .filter((item) => item.selected === true)
          .forEach((item) => {
            this.searchOptionsProcessed.criteria.pageTitles.push(item.value);
          });

        this.searchOptionsProcessed.criteria.categoryTitles = [];
        newFilter.categoryTitles
          .filter((item) => item.selected === true)
          .forEach((item) => {
            this.searchOptionsProcessed.criteria.categoryTitles.push(item.value);
          });

        //console.log('---- searchOptionsProcessed changed by filterChange', this.searchOptionsProcessed)
      },
      searchPhraseChange(newSearchPhrase) {
        this.searchOptionsProcessed.searchPhrase = newSearchPhrase;
      },
      manageSearchObject() {
        this.searchOptionsProcessed = {
          searchPhrase: this.mxn_getFromNestedObject(() => this.searchOptions.searchPhrase, ''),
          criteria: {
            typeNames: this.mxn_getFromNestedObject(() => this.searchOptions.criteria.typeNames, []),
            pageSids: this.mxn_getFromNestedObject(() => this.searchOptions.criteria.pageSids, []),
            pageTitles: this.mxn_getFromNestedObject(() => this.searchOptions.criteria.pageTitles, []),
            categoryTitles: this.mxn_getFromNestedObject(() => this.searchOptions.criteria.categoryTitles, []),
          },
          display: {
            searchPhrase: this.mxn_getFromNestedObject(() => this.searchOptions.display.searchPhrase, false),
            typeNames: this.mxn_getFromNestedObject(() => this.searchOptions.display.typeNames, false),
            pageTitles: this.mxn_getFromNestedObject(() => this.searchOptions.display.pageTitles, false),
            categoryTitles: this.mxn_getFromNestedObject(() => this.searchOptions.display.categoryTitles, false),
          },

          // Exactly now
          courseDateSetStartDateFrom: this.formatDateTime(new Date(Date.now()), 'yyyy-MM-dd', 0),

          // 30 days ago
          // courseDateSetStartDateFrom: this.formatDateTime(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', 0),

          linkExternal: this.mxn_getFromNestedObject(() => this.searchOptions.linkExternal, false),
          displayHelpArticlesOnLoad: this.mxn_getFromNestedObject(() => this.searchOptions.displayHelpArticlesOnLoad, false),
          results: 100,
          blogArticleTargetPage: this.mxn_getFromNestedObject(() => this.searchOptions.blogArticleTargetPage, undefined),
          helpArticleTargetPage: this.mxn_getFromNestedObject(() => this.searchOptions.helpArticleTargetPage, undefined),
        };
      },
      manageFilterObject() {
        let filter = {
          typeNames: [
            { value: 'Verein', count: 0, selected: false },
            { value: 'Aboangebot', count: 0, selected: false },
            { value: 'Kursangebot', count: 0, selected: false },
            { value: 'Blogartikel', count: 0, selected: false },
          ],
          pageTitles: [],
          categoryTitles: [],
        };

        this.filterCount = 0;

        // temp solution to not display "Hilfeartikel"
        // filter = this.manageFilterObjectHandeOption(filter, 'typeNames', 'typeAggs');

        filter = this.manageFilterObjectHandeOption(filter, 'pageTitles', 'pageAggs');
        filter = this.manageFilterObjectHandeOption(filter, 'categoryTitles', 'categoryAggs');

        if (this.searchOptionsProcessed.searchPhrase !== '') this.filterCount++;

        // release

        this.filterOptionsProcessed = filter;

        //console.log('---- manageFilterObject', this.filterOptionsProcessed.searchPhrase)
      },
      manageFilterObjectHandeOption(filter, filterName, filterNameAggs) {
        // stream in all data from the elastic aggregations

        this.searchResult.aggregations[filterNameAggs].buckets.forEach((aggItem) => {
          if (filter[filterName].find((filterItem) => filterItem.value === aggItem.key)) {
            filter[filterName].find((filterItem) => filterItem.value === aggItem.key).count = aggItem.doc_count;
          } else {
            filter[filterName].push({
              value: aggItem.key,
              count: aggItem.doc_count,
              selected: false,
            });
          }
        });

        // update with information from searchObject

        if (this.searchOptionsProcessed && this.searchOptionsProcessed.criteria && Array.isArray(this.searchOptionsProcessed.criteria[filterName])) {
          this.searchOptionsProcessed.criteria[filterName].forEach((optionsItem) => {
            let foundFilterItem = filter[filterName] && filter[filterName].find((filterItem) => filterItem.value === optionsItem);
            if (foundFilterItem) {
              foundFilterItem.selected = true;
              if (this.searchOptionsProcessed.display && this.searchOptionsProcessed.display[filterName] === true) this.filterCount++;
            }
          });
        }

        return filter;
      },
      search(options) {
        const currentSearchId = Date.now(); // Generate a unique ID for this search based on current timestamp
        this.latestSearchId = currentSearchId; // Store it

        // Ensure offset doesn't exceed the total number of hits
        if (this.offset > this.countHits) {
          this.offset = this.countHits - this.pageSize;
        }

        elasticSearch
          .result({
            ...this.searchOptionsProcessed,
            from: this.offset,
            size: this.pageSize,
          })
          .then((result) => {
            if (this.latestSearchId === currentSearchId) {
              // Check if this result is for the latest search
              console.log('---- currentSearchId', currentSearchId, 'latestSearchId', this.latestSearchId, 'difference', this.latestSearchId - currentSearchId, 'accept', 'result', result);

              if (options && options.loadMore === true) {
                this.searchResult.hits.hits = this.searchResult.hits.hits.concat(result.hits.hits);
              } else {
                this.offset = 0;
                this.pageSize = 48;
                this.searchResult = result;
              }

              if (this.searchResult.hits.hits.length >= this.countHits) {
                this.allResultsLoaded = true;
              } else {
                this.allResultsLoaded = false;
              }

              this.manageFilterObject();

              this.$emit('number-of-hits-changed', this.countHits);

              this.loading = false;
            } else {
              console.log('---- currentSearchId', currentSearchId, 'latestSearchId', this.latestSearchId, 'difference', this.latestSearchId - currentSearchId, 'ignore', 'result', result);
            }
          })
          .catch((error) => {
            console.error('Error performing search:', error);
          });
      },
      openFilterDialog() {
        this.$refs.filterDialog.enter();
      },
    },
    created() {
      this.manageSearchObject();
    },
  };
</script>
<style lang="stylus"></style>
